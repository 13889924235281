<template>
	<v-container v-if="$can('view', 'Template')" fluid>
		<div v-if="$can('create', 'Template')" class="text-right">
			<v-btn rounded color="primary" dark @click="showCreateModal()">
				+ Create Template
			</v-btn>
		</div>

		<div class="mt-4 d-flex flex-column">
			<div class="mb-4 mt-8" v-if="templates.length > 0">
				<v-row class="mx-0">
					<v-col
						cols="6"
						v-for="template in templates"
						:key="template.id"
						class="pa-0 pr-2 d-flex"
					>
						<v-card width="100%">
							<div class="pa-4">
								<div
									class="
										d-flex
										flex-row
										justify-space-between
										align-start
									"
								>
									<div class="content">
										{{ template.template }}
									</div>
									<div class="status pa-0 ml-2">
										<div
											v-if="
												template.type === 'GMB_REVIEW'
											"
										>
											<v-chip class="gmb-chip">
												GMB Review
											</v-chip>
										</div>
										<div v-else>
											<v-chip class="reply-chip">
												Reply to Review
											</v-chip>
										</div>
									</div>
								</div>
								<div class="pt-3 d-flex flex-row align-center">
									<p class="pb-1 pr-3">GMB locations</p>
									<div
										v-for="location in template.validLocations"
										:key="location.id"
									>
										<v-chip
											:color="
												location.isUsed
													? 'success'
													: 'primary'
											"
											outlined
										>
											{{ location.locationName }}
										</v-chip>
									</div>
								</div>
							</div>
							<v-divider></v-divider>
							<div
								class="
									card-actions
									pa-4
									d-flex
									flex-row
									justify-space-between
								"
							>
								<UnoEditButton
									v-if="$can('update', 'Template')"
									@click.native="editTemplate(template)"
								></UnoEditButton>

								<a
									v-if="template.type === 'GMB_REVIEW'"
									@click="whatsapp(template)"
								>
									<v-btn class="whatsapp" text color="green">
										<v-icon left> mdi-whatsapp </v-icon>
										Share on WhatsApp
									</v-btn>
								</a>
							</div>
						</v-card>
					</v-col>
				</v-row>
			</div>

			<NoData v-else></NoData>

			<div>
				<div
					v-if="openModal"
					class="justify-center d-flex align-center"
				>
					<CreateTemplate
						:initial-data="template"
						@on-template-update="updateListing"
						@close-modal="closeModal"
					></CreateTemplate>
				</div>
			</div>
		</div>

		<Paginator
			:paginator="paginator"
			:url="`/${$store.state.app.projectId}/templates`"
			@update="changePage($event)"
		></Paginator>
	</v-container>
</template>

<script>
import Paginator from '../../components/Paginator'
import NoData from '../../components/NoData'
import UnoEditButton from '../../components/Buttons/UnoEditButton'
import CreateTemplate from './createTemplate'
export default {
	data() {
		return {
			openModal: false,
			templates: [],
			paginator: {},
			template: {},
		}
	},
	components: {
		NoData,
		Paginator,
		UnoEditButton,
		CreateTemplate,
	},
	created() {
		this.getListingData()
	},
	methods: {
		whatsapp(template) {
			const projectId = this.$store.state.app.projectId
			function listener(event) {
				event.clipboardData.setData('text/html', template.template)
				event.clipboardData.setData('text/plain', template.template)
				event.preventDefault()
			}
			document.addEventListener('copy', listener)
			document.execCommand('copy')
			document.removeEventListener('copy', listener)
			window.open('https://web.whatsapp.com', '_blank')
			this.axios({
				method: 'delete',
				url: `/${projectId}/templates/${template.id}`,
			})
		},
		showCreateModal() {
			this.openModal = true
			this.template = {}
		},
		updateListing() {
			this.getListingData()
			this.closeModal()
		},
		getListingData() {
			const projectId = this.$store.state.app.projectId
			this.axios({
				method: 'get',
				url: `/${projectId}/templates`,
			}).then((response) => {
				this.templates = response.data.data
				this.paginator = response.data.paginator
			})
		},
		updatePaginator(value) {
			this.$store.commit('template/updatePaginator', value)
		},
		editTemplate(template) {
			this.template = template
			this.openModal = true
		},
		closeModal() {
			this.openModal = !this.openModal
		},
		changePage(data) {
			this.templates = data.data
			this.paginator = data.paginator
		},
	},
}
</script>

<style scoped>
.gmb-chip {
	background-color: var(--blue-bg) !important;
	color: #00a63f !important;
	border-radius: 7px !important;
	display: flex !important;
	justify-content: center;
}
.reply-chip {
	background-color: #ffe0b2 !important;
	color: #bf360c !important;
	border-radius: 7px !important;
	display: flex !important;
	justify-content: center;
}
.mdi-whatsapp {
	font-size: 20px;
}
.status {
	width: 130px !important;
}
</style>
