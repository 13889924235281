<template>
	<v-row
		v-if="$can('create', 'Template') || $can('update', 'Template')"
		justify="center"
	>
		<v-dialog v-model="dialog" persistent max-width="800px">
			<v-card class="mb-0">
				<div class="d-flex justify-space-between align-start px-6 pt-6">
					<h1 class="font-weight-light crud-title mb-5">
						Add Template
					</h1>
					<span class="mdi mdi-close close" @click="reset()"></span>
				</div>
				<v-col cols="12" class="px-6 py-0">
					<v-row class="d-flex align-center">
						<v-col cols="12" class="pa-0">
							<v-textarea
								solo
								flat
								name="template"
								label="Enter Text for template*"
								no-resize
								outlined
								counter="4000"
								v-model="template"
							></v-textarea>
							<p class="ma-0 label">Type:</p>
							<v-radio-group v-model="type" row>
								<v-radio
									label="Reply Template"
									value="REVIEW_REPLY"
								></v-radio>
								<v-radio
									label="Review Template"
									value="GMB_REVIEW"
								></v-radio>
							</v-radio-group>
						</v-col>
					</v-row>

					<v-col cols="12"
						><p class="ma-0 label">GMB locations:</p>
						<Search
							:initialData="validLocations"
							:url="`/${$store.state.app.projectId}/gmb/location/search`"
							@search-result="updateGMBLocations($event)"
						></Search>
					</v-col>
				</v-col>

				<v-divider></v-divider>

				<v-card-actions class="pa-6 card-actions">
					<UnoSaveButton
						@click.native="saveData()"
						@primary-button-click="templateListing()"
						primary-button-text="Move To Template Listing"
						secondary-button-text="Continue Editing"
						:saving="isSaving"
					></UnoSaveButton>

					<v-spacer></v-spacer>

					<UnoDeleteButton
						v-if="isEdit && $can('delete', 'Template')"
						@confirm="deleteTemplate()"
						:deleting="deleting"
					></UnoDeleteButton>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import Search from './components/Search'
import { required } from 'vuelidate/lib/validators'
// ACTION BUTTONS
import UnoSaveButton from '@/components/Buttons/UnoSaveButton'
import UnoDeleteButton from '@/components/Buttons/UnoDeleteButton'
export default {
	props: {
		initialData: {
			type: Object,
			required: true,
			default: () => {},
		},
	},
	components: {
		UnoSaveButton,
		UnoDeleteButton,
		Search,
	},
	data() {
		return {
			loading: false,
			dialog: true,
			type: 'REVIEW_REPLY',
			template: '',
			validLocations: [],
			isEdit: false,
			isSaving: false,
			id: null,
			deleting: false,
		}
	},
	validations: {
		template: {
			required,
		},
	},
	created() {
		if (Object.keys(this.initialData).length) {
			this.template = this.initialData.template
			this.validLocations = this.initialData.validLocations
			this.type = this.initialData.type
			this.isEdit = true
			this.id = this.initialData.id
		}
	},
	methods: {
		saveData() {
			this.isSaving = true
			const projectId = this.$store.state.app.projectId

			let payload = {
				template: this.template,
				type: this.type,
				validLocations: this.validLocations.map((location) => {
					return {
						gmbLocation: location.gmbLocation,
						locationName: location.locationName,
						locationAddress: location.locationAddress,
					}
				}),
			}
			let templateId = this.id
			this.axios({
				method: this.isEdit ? 'put' : 'post',
				url: this.isEdit
					? `/${projectId}/templates/${templateId}`
					: `/${projectId}/templates`,
				data: payload,
			}).then(() => {
				this.isSaving = false
				this.$emit('on-template-update')
			})
		},
		updateGMBLocations(data) {
			this.validLocations = data
		},
		reset() {
			if (!this.isEdit) {
				this.template = ''
				this.validLocations = []
				this.type = ''
			}
			this.$emit('close-modal')
		},
		deleteTemplate() {
			this.deleting = true
			const projectId = this.$store.state.app.projectId
			this.axios
				.delete(`/${projectId}/templates/${this.id}`)
				.then(() => this.$emit('on-template-update'))
			this.deleting = false
		},
	},
}
</script>

<style scoped>
.headline {
	font-weight: 400 !important;
}
.save-btn {
	color: white !important;
}
.trash-btn:hover {
	background-color: #ffebee;
}
.restore-btn:hover {
	background-color: #e8f5e9;
}
.card-actions .icon {
	font-size: 17px;
}
</style>
